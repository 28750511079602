.portal-logo {
    height: 55px;
}
.web-nav-cap {
    display: flex;
    justify-content: space-between;
    padding: 8px 95px;
}
.nav-menu {
    display: flex;
    padding-top: 1em;
}
.nav-menu li {
    margin-right: 20px;
}