.auth-page {
    height: 100vh;
    width: 100%;
    position: relative;
    background-image: url('../assets/images/authbg.jpg');
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.auth-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 360px;
    height: fit-content;
    width: 28%;
    box-shadow: 2px 2px 2px 2px #eeeeee67;
    border-radius: 10px;
}

.auth-logo {
    height: 5rem;
}

.auth-cap-txt {
    font-size: 12px;
    font-weight: 500;
}

.auth-label {
    font-size: 10px;
}

.auth-oth-link {
    font-size: 12px;
    font-weight: 500;
}

.pass {
    position: relative;
}

.pass small {
    position: absolute;
    top: 78%;
    right: 8px;
    transform: translateY(-78%);
    cursor: pointer;
    opacity: 0.5;
    font-size: 12px;
}