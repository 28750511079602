@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}

body {
  background-color: #fafbfe !important;
  font-family: 'Assistant', sans-serif;
}

a {
  text-decoration: none;
}

ul {
  display: inline;
}

li {
  list-style: none;
}

button {
  border: none;
  outline: none;
}

img {
  object-fit: contain;
  width: 100%;
}

label {
  font-weight: 400 !important;
}
.label-i {
  font-weight: 500 !important;
  color: #151515;
}

.p-rel {
  position: relative;
}

.a-col {
  color: #2980AB;
}
.b-col {
  color: #b5151a;
}