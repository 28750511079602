/* ====================  STUDIES PAGES =========== */
.ss-bg {
    background-color: #F1F3F9;
}

.react-tabs__tab-list {
    display: flex;
}

.react-tabs__tab {
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    margin-right: 0.8rem;
    cursor: pointer;
    border-radius: 6px;
}

.react-tabs__tab--selected {
    background-color: #3177A5;
    color: #fff;
}

.react-tabs__tab-panel {
    padding: 0rem 2rem;
}

/* ============  staff page =========== */
.iv-staff-bg {
    border-radius: 50%;
    background-color: #3177A5;
    height: 50px;
    width: 50px;
    position: relative;
    margin-right: 15px;
}

.iv-staff-bg h6 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


/*  ========== ====== NEW SITE PAGE ---======== */
.form-site-in {
    font-size: 11px;
    font-weight: 500;
}


/* ============= CLIENT SETTINGS PAGE========== */
.settings-menu {
    background-color: #fff;
    height: 75vh;
    padding: 15px 10px;
    border-radius: 5px;
}
.s-menu-top {
    margin-bottom: 25px;
}
.settings-menu h5 {
    font-size: 18px;
    font-weight: 600;
}
.settings-menu p {
    font-size: 14px;
    font-weight: 400;
}
.s-menu-list li a {
    display: block;
    width: 100%;
    color: #000;
    font-size: 15px;
    width: 100%;
    margin-bottom: 4px;
    padding: 12px 4px;
    transition: background-color 0.3s ease;
}

.s-menu-list li a:hover {
    color: #61A1C0;
    font-weight: 600;
    font-size: 15px;
}
.s-menu-list li a.active { 
    color: #61A1C0;
    font-weight: 600;
    font-size: 15px;
}


/* ============================== INVESTIGATORS PAGE ========= */
.si-sec-b h6 {
    color: #333;
    font-size: 13px;
    font-weight: 500;
}
.si-sec-b p, .si-sec-b li {
    font-size: 16px;
    font-weight: 700;
    color: #b5151a;
}
.si-sec-b li {
    list-style: disc;
}