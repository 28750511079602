.App {
  min-height: 100vh;
  width: 100%;
}

/* nav {
  width: 100%;
  background: #fff;
} */

