.mobile-header {
    display: none;
}
.mobile-action-section {
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #000;
}
.mobile-meu-close-btn {
    font-size: 32px;
    font-weight: 600;
}
.mobile-menu-list {
    height: 100%;
    width: 100%;
}

.mobile-menu-list li {
    width: 100%;
    padding: 10px 15px;
    border-bottom: 1px solid #000;
}

@media only screen and (max-width: 600px) {
    /* * {
        width: 100%;
    } */
    .auth-form  {
        width: 85%;
        min-height: 65vh;
        height: fit-content;
    }

    .web-nav {
        display: none;
    }
    .mobile-header {
        width: 100%;
        padding: 10px 10px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: 0;
        left: 0;
        box-shadow: 2px 2px 1px #ccc;
    }

    .mobile-menu {
        height: 100vh;
        width: 100%;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 8;
        /* display: none; */
    }
}