/* .home-banner-caption {
    width: 70%;
    margin: 10px auto;
} */
.home-banner-caption h1 {
    font-size: 48px;
    font-weight: 700;
    /* padding: 10px 45px; */
}
.home-banner-caption p {
    font-size: 22px;
    font-weight: 500;
    /* padding: 10px 5px; */
}
.banner-img {
    display: flex;
    justify-content: center;
}
.home-banner {
    border-radius: 12px;
    width: 85%;
    height: 450px;
    /* margin: 0px auto; */
    object-fit: cover;
}
.serv-htext {
    font-size: 24px;
    font-weight: 600;
}
.serv-c-m {
    font-size: 18px;
    font-weight: 500;
}
.serv-cap {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 55px;
}
.serv-card {
    border-radius: 14px;
    min-height: 80px;
    box-shadow: 2px 2px 2px 2px #5453536a;
    /* position: relative; */
}
.serv-card .serv-c-icon {
    /* color: #B51318; */
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 15px;
}
.serv-card h5 {
    /* position: absolute;
    bottom: 6px;
    left: 15px; */
    font-size: 18px;
    font-weight: 500;
}

.gts {
    background-image: url('../assets/images/homedna.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
}