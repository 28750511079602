.count-tag {
    padding: .2rem 1rem;
    border-radius: 8px 8px 8px 8px;
    font-size: 12px;
    font-weight: 600;
}

.ct-tag1-col {
    background-color: #acd6bb;
}

.ct-tag2-col {
    background-color: #86EFAC;
}

.ct-tag3-col {
    background-color: #86EFAC;
}

.s-status-btn {
    background-color: #b5151a;
    color: #fff;
}

.s-details-btn {
    height: 2.5rem;
    background-color: #3177A5;
    color: #fff;
    border-radius: 1.6rem;
    padding: 0rem 1.2rem;
}

.submission-date-btn {
    background-color: #f1f0f1;
    border-radius: 6px;
}

.auth-action-btn {
    background-color: #b5151a;
    color: #fff;
    padding: 0.2rem 1rem;
    border-radius: 24px;
}

/* =============================== */
.btn-a {
    background-color: #2980AB;
    color: #fff;
    padding: 9px 30px;
    border: 1px solid #ccc;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
}

.btn-a-sm {
    background-color: #2980AB;
    color: #fff;
    padding: 6px 13px;
    border: 1px solid #ccc;
    font-weight: 500;
    font-size: 0.9rem;
    border-radius: 4px;
}

.btn-a:hover {
    background-color: #B81E23;
}

.btn-a-sm:hover {
    background-color: #B81E23;
}

.btn-b {
    background-color: #fff;
    color: #2980AB;
    padding: 9px 30px;
    border: 1px solid #ccc;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
}

.btn-b-sm {
    background-color: #fff;
    color: #2980AB;
    padding: 6px 13px;
    border: 1px solid #ccc;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
}

.btn-b:hover {
    color: #b5151a;
}

.btn-b-sm:hover {
    color: #b5151a;
}

.btn-c {
    background-color: #b5151a;
    color: #fff;
    padding: 9px 30px;
    border: 1px solid #ccc;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
}

.btn-c-sm {
    background-color: #b5151a;
    color: #fff;
    padding: 6px 13px;
    border: 1px solid #ccc;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
}

.btn-c:hover {
    background-color: #2980AB;
}

.btn-c-sm:hover {
    background-color: #2980AB;
}

.btn-d {
    background-color: #fff;
    color: #b5151a;
    padding: 9px 30px;
    border: 1px solid #ccc;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
}

.btn-d-sm {
    background-color: #fff;
    color: #b5151a;
    padding: 6px 13px;
    border: 1px solid #ccc;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
}

.btn-d:hover {
    color: #2980AB;
}

.btn-d-sm:hover {
    color: #2980AB;
}

.in-nav {
    padding: 9px 15px !important;
    margin-right: 7px;
}